export type PartnerItemType = {
    companyName: string;
    logoSrc: string;
}

export const partnerData: PartnerItemType[] = [
    {
        companyName: 'BTB',
        logoSrc: '/static/images/home-page/partners/btb.svg',
    },
    {
        companyName: 'Při Točně',
        logoSrc: '/static/images/home-page/partners/pri-tocne.svg',
    },
    {
        companyName: 'Limo Gang',
        logoSrc: '/static/images/home-page/partners/limogang.svg',

    },
    {
        companyName: 'Povaleč',
        logoSrc: '/static/images/home-page/partners/povalec.svg',
    }
];
