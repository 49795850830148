import React from "react";
import styled from "styled-components";
import TransHTML from "~components/transHTML";
import {mobileBreakpoint, smallScreenBreakpoint1064px, tabletBreakpoint} from "~styles/common";
import {BenefitType} from "~components/features/types";

type PropTypes = {
    item: BenefitType;
}

export function FeatureCarouselItem({ item }: PropTypes) {
    return (
        <Wrapper>
            <Block>
                <HomePageHeading>
                    <TransHTML id={item.text} />
                </HomePageHeading>
                <SvgImg src={`${item.icon}`} />
            </Block>
            <Paragraph>
                <TransHTML id={item.subText} />
            </Paragraph>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    background: rgba(235, 231, 247, 0.5);
    border-radius: 16px;
    padding: 1rem;
    margin: 0 .5rem;
    text-align: left;
    position: relative;
    min-height: 228px;

    @media (min-width: 550px) {
        min-height: 328px;
    }

    @media (min-width: ${tabletBreakpoint}) {
        padding: 2rem;
        max-width: none;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        margin: 0 2rem;
        padding: 3rem;
        max-width: 400px;
    }
`;


const HomePageHeading = styled.h3`
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.5;
    max-width: 205px;
    margin-bottom: 1rem;

    @media (min-width: ${mobileBreakpoint}) {
        font-weight: 700;
        font-size: 1.4rem;
    }
`;

const Paragraph = styled.p`
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 280px;
    }
    @media (min-width: ${smallScreenBreakpoint1064px}) {
       max-width: 425px;
    }
`;

const SvgImg = styled.img`
    width: 62px !important;
    height: 62px !important;
`;

const Block = styled.div`
    display: flex;
    justify-content: space-between;
`;
