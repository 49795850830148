import React from "react";
import styled from "styled-components";

type PropTypes = {
    review: number;
};

export function RatingByStars({ review }: PropTypes) {
    const buildArray = [...Array(review)];
    return (
        <Wrapper>
            {buildArray.map((x, i) => (
                <Star
                    key={i}
                    src={'/static/images/home-page/star.svg'}
                    alt={"Star"}
                />
            ))}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    gap: 5px;
    padding: .4rem 1rem ;
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.21);
    border-radius: 52px;
`;

const Star = styled.img`

`;
