import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT } from "~styles/variables";

type PropTypes = {
    id: string;
};

function InPageAnchor({ id }: PropTypes) {
    return (
        <StyledAnchor id={id} />
    );
}

export default InPageAnchor;

const StyledAnchor = styled.a`
    position: absolute;
    left: 0;
    top: 0;

    height: ${HEADER_HEIGHT}px;
    margin-top: -${HEADER_HEIGHT}px;
    padding-top: ${HEADER_HEIGHT}px;
    scroll-margin-top: ${HEADER_HEIGHT}px;
`;
