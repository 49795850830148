import React from "react";
import {smallScreenBreakpoint1064px} from "~styles/common";
import styled from "styled-components";
import {PartnerItemType} from "~components/homepage/why-business/partner-data";

type PropTypes = {
    data: PartnerItemType[];
}

export function Partners({ data }: PropTypes) {
    return (
        <Wrapper>
            {data.map((item) => (
                <Logo
                    key={item.companyName}
                    src={item.logoSrc}
                    alt={item.companyName}
                />
            ))}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: none;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        display: flex;
        justify-content: center;
        gap: 2.2rem;
        margin-top: 5rem;
    }
`;

const Logo = styled.img`
`;
